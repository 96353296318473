<template>
    <div class="taskSendSignature">
        <ConfirmDialog ref="confirmDialog"/>

        <DownloadContract v-if="task.contract" :contract-name="task.contract.name" :task-id="task.id"/>
        <CBadge v-if="task.is_main" style="font-size: 10px" color="primary" class="ml-2">Primary</CBadge>
        <CCard class="mb-4 customCardTab">
            <card-head-navigation v-if="task.id" :task="task"/>

            <CCardBody>
                <CCol>
                    <h2 class="taskSendSignature__title mb-4">Signers</h2>
                </CCol>
                <CCard class="mb-4">
                    <CCardBody>
                        <CRow>
                            <CCol sm="12" md="6">
                                <CRow>
                                    <CCol sm="12" md="6" class="taskSendSignature__type mb-2">
                                        Receiving Company Signer
                                    </CCol>
                                    <CCol sm="12" md="6" class="mb-2">
                                        <CSelectSearch v-model="receiving_signer_id"
                                                       :options="usersList.external_signers"
                                                       placeholder="Select User" style="width: 100%"
                                                       :error="errors.receiving_signer_id" :loading="loadingUsers"/>
                                    </CCol>
                                </CRow>
                            </CCol>
                            <CCol sm="12" md="6">
                                <CRow class="taskSendSignature__row">
                                    <CCol cols="6">Company</CCol>
                                    <CCol cols="6" class="taskSendSignature__row-value ellipsis">
                                        {{ sendingUser?.company_name || 'N/A' }}
                                    </CCol>
                                </CRow>
                                <CRow class="taskSendSignature__row">
                                    <CCol cols="6">Job Title</CCol>
                                    <CCol cols="6" class="taskSendSignature__row-value ellipsis">
                                        {{ sendingUser?.job_title || 'N/A' }}
                                    </CCol>
                                </CRow>
                                <CRow class="taskSendSignature__row">
                                    <CCol cols="6">Email</CCol>
                                    <CCol cols="6" class="taskSendSignature__row-value ellipsis">
                                        {{ sendingUser?.email || 'N/A' }}
                                    </CCol>
                                </CRow>
                                <CRow class="taskSendSignature__row">
                                    <CCol cols="6">Role</CCol>
                                    <CCol cols="6" class="taskSendSignature__row-value ellipsis">
                                        {{ sendingUser?.company_type?.capitalize() || 'N/A' }}
                                    </CCol>
                                </CRow>
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
                <CCard class="mb-4">
                    <CCardBody>
                        <CRow>
                            <CCol sm="12" md="6">
                                <CRow>
                                    <CCol sm="12" md="6" class="taskSendSignature__type mb-2">
                                        Sending Company Signer
                                    </CCol>
                                    <CCol sm="12" md="6" class="mb-2">
                                        <CSelectSearch v-model="sending_signer_id" :options="usersList.internal_signers"
                                                       placeholder="Select User" style="width: 100%"
                                                       :error="errors.sending_signer_id" :loading="loadingUsers"/>
                                    </CCol>
                                </CRow>
                            </CCol>
                            <CCol sm="12" md="6">
                                <CRow class="taskSendSignature__row">
                                    <CCol cols="6">Company</CCol>
                                    <CCol cols="6" class="taskSendSignature__row-value ellipsis">
                                        {{ receivingUser?.company_name || 'N/A' }}
                                    </CCol>
                                </CRow>
                                <CRow class="taskSendSignature__row">
                                    <CCol cols="6">Job Title</CCol>
                                    <CCol cols="6" class="taskSendSignature__row-value ellipsis">
                                        {{ receivingUser?.job_title || 'N/A' }}
                                    </CCol>
                                </CRow>
                                <CRow class="taskSendSignature__row">
                                    <CCol cols="6">Email</CCol>
                                    <CCol cols="6" class="taskSendSignature__row-value ellipsis">
                                        {{ receivingUser?.email || 'N/A' }}
                                    </CCol>
                                </CRow>
                                <CRow class="taskSendSignature__row">
                                    <CCol cols="6">Role</CCol>
                                    <CCol cols="6" class="taskSendSignature__row-value ellipsis">
                                        {{ receivingUser?.company_type?.capitalize() || 'N/A' }}
                                    </CCol>
                                </CRow>
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>

                <CRow>
                    <CCol class="d-flex align-items-center flex-wrap pt-4 pb-4">
                        <CButton :loading="sending" color="primary"
                                 class="m-2 ml-0 d-flex align-items-center justify-content-center btn-send"
                                 @click="handleSave">
                            <img src="@/assets/images/send.png" class="m-2 mt-0 mb-0"/>
                            Send For Signatures
                        </CButton>
                        <CButton color="primary" variant="outline" class="m-2 ml-0 btn-cancel" @click="handleCancel">
                            Cancel
                        </CButton>
                    </CCol>
                </CRow>
            </CCardBody>
        </CCard>

        <CCard class="mb-4">
            <CCardBody>
                <CCol>
                    <h2 class="taskSendSignature__title mb-4 d-flex align-items-center">
                        Tasks related with current task
                        <img src="@/assets/arrow_down.svg" class="taskSendSignature-arrow-table ml-2"
                             :style="`transform: rotate(${tableVis ? 180 : 0}deg)`" @click="tableVis = !tableVis"/>
                    </h2>
                </CCol>
                <SmartTable v-if="tableVis" :items="tasks" :columns="fields" :loading="loadingUsers"
                            :sorter-value="sort" :clear-text="'No Data yet'" infinity class="mb-4"
                            :selected-line="(item) => item.is_current" @sorter-change="onSort">
                    <template #name="{ item }">
                        <div class="taskSendSignature-line">
                            <template v-if="item._id">
                                <div class="taskSendSignature-line-line"
                                     :class="{ last: item._id === tasks.length - 1 }"/>
                                <div class="taskSendSignature-line-center"/>
                            </template>
                            <template v-else>
                                <img src="@/assets/crown.svg"/>
                            </template>
                        </div>

                        <a style="text-decoration: underline; cursor: pointer">{{ item.name || 'N/A' }}</a>
                    </template>
                </SmartTable>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import errors from '@/mixin/errors'
import SubmitBtn from '@/components/SubmitBtn'
import DownloadContract from '../components/DownloadContract'
import table from '@/mixin/table'
import {isEqual} from 'lodash/lang'
import ConfirmDialog from '@/components/Modals/ConfirmDialog'
import {mapGetters} from 'vuex'
import CSelectSearch from '@/components/CSelectSearch'
import ApiService from '@/services/ApiService'
import useTaskSignersAndAssigners from '@/composables/useTaskSignersAndAssigners'
import Gate from '@/services/Gate/Gate'

export default {
    name: 'TaskSendSignature',
    // TODO replace to Gate and middleware
    async beforeRouteEnter(to, from, next) {
        await ApiService.api.tasks
            .getTask({params: {task_id: to.params.task_id}})
            .then((res) => {
                if (res.data.data?.contract?.isApproved !== true || res.data.data.is_external) {
                    next({name: 'ProjectTasks', params: {id: to.params.id}})
                } else {
                    next()
                }
            })
            .catch(() => {
                next({name: 'ProjectTasks', params: {id: to.params.id}})
            })
    },
    async beforeRouteLeave() {
        if (this.isEditForm) {
            await this.$refs.confirmDialog
                .confirm({
                    text: this.$t('messages.unsaved'),
                    confirmText: 'Leave',
                    cancelText: 'Cancel',
                    reverse: true,
                })
                .then((res) => {
                    if (res) return true
                    else {
                        return Promise.reject(false)
                    }
                })
        } else {
            return true
        }
    },
    mixins: [errors, table],
    components: {
        SubmitBtn,
        DownloadContract,
        ConfirmDialog,
        CSelectSearch,
    },
    inject: ['toast'],
    setup() {
        const {usersList, getUsers, loadingUsers} = useTaskSignersAndAssigners()
        return {usersList, getUsers, loadingUsers}
    },
    mounted() {
        this.fetchData()
        this.fetchDataInfo()
    },
    data() {
        return {
            origin: {},
            task: {},
            receiving_signer_id: null,
            sending_signer_id: null,
            contract_id: null,
            tableVis: true,
            tasks: [],
            receivingUsersList: [],
            myUsersList: [],
            fields: [
                // { key: 'line', label: '', sorter: false, _style: 'max-width: 30px; width: 30px' },
                {
                    key: 'name',
                    label: 'Task Name',
                    click: (item) => this.goToView(item),
                },
                {
                    key: 'task_id',
                    label: 'Task ID',
                    click: (item) => this.goToView(item),
                },
                {
                    key: 'due_date',
                    label: 'Due Date',
                    format: (value) => (value ? this.$BtoF({value}) : 'N/A'),
                },
                {key: 'status', label: 'Status'},
                {key: 'priority', label: 'Priority'},
                {key: 'task_priority', label: 'Task (Primary, Secondary)'},
                {
                    key: 'is_internal',
                    label: 'Internal/External',
                    format: (value) => (value ? 'Internal' : 'External'),
                },
            ],
            sending: false,
        }
    },
    computed: {
        ...mapGetters({
            user: 'user',
        }),

        isEditForm() {
            return !isEqual(
                {
                    receiving_signer_id: this.receiving_signer_id,
                    sending_signer_id: this.sending_signer_id,
                },
                this.origin,
            )
        },
        routerAfterChange() {
            return {name: 'ProjectTasks', params: {id: this.selectProject}}
        },
        receivingUser() {
            const find = this.usersList.internal_signers.find(
                (item) => item.data.id === this.sending_signer_id,
            )
            return find ? find.data : null
        },
        sendingUser() {
            const find = this.usersList.external_signers.find(
                (item) => item.data.id === this.receiving_signer_id,
            )
            return find ? find.data : null
        },
    },
    methods: {
        goToView(item) {
            if (item.type === 'Request documents') {
                this.$router.push({
                    name: 'TaskRequest',
                    params: {id: this.$route.params.id, task_id: item.id},
                })
            } else if (item.type === 'Contracts') {
                this.$router.push({
                    name: 'TaskContracts',
                    params: {id: this.$route.params.id, task_id: item.id},
                })
            } else if (item.type === 'Acknowledge') {
                this.$router.push({
                    name: 'TaskAcknowledge',
                    params: {id: this.$route.params.id, task_id: item.id},
                })
            }
        },
        fetchData() {
            this.$http.library
                .relatedTasks({
                    params: {
                        task_id: this.$route.params.task_id,
                        sort_field: (this.sort && this.sort.column) || 'task_id',
                        sort_direction: (this.sort && this.sort.state) || 'desc',
                    },
                })
                .then((res) => {
                    this.tasks = res.data.data
                })
        },
        fetchDataInfo() {
            this.loadingUsers = true
            this.$http.tasks
                .getTask({params: {task_id: this.$route.params.task_id}})
                .then((res) => {
                    if (
                        res.data.data?.contract?.isApproved !== true ||
                        res.data.data.is_external
                    ) {
                        this.$router.push({
                            name: 'ProjectTasks',
                            params: {id: this.$route.params.id},
                        })
                    }

                    this.task = res.data.data
                    this.contract_id = res.data.data.contract?.id
                    this.receiving_signer_id = res.data.data.external_signer?.id
                    this.sending_signer_id = res.data.data.internal_signer?.id

                    this.origin.receiving_signer_id = res.data.data.external_signer?.id
                    this.origin.sending_signer_id = res.data.data.internal_signer?.id

                    this.getUsers(this.task.scope_id)
                })
        },
        async handleCancel() {
            if (this.isEditForm) {
                await this.$refs.confirmDialog
                    .confirm({
                        text: this.$t('messages.unsaved'),
                        cancelText: 'Cancel',
                        confirmText: 'OK',
                        reverse: true,
                    })
                    .then((res) => {
                        if (res) {
                            this.cancel()
                        }
                    })
            } else {
                this.cancel()
            }
        },
        cancel() {
            this.fetchDataInfo()
        },
        async handleSave() {
            await this.$refs.confirmDialog
                .confirm({
                    text: 'Are you sure you want to send the contract for signature?',
                    info: ' It will not be possible to edit the contract after this action.',
                    confirmText: 'Confirm',
                    cancelText: 'Cancel',
                    reverse: true,
                })
                .then((res) => {
                    if (res) this.save()
                })
        },
        save() {
            this.sending = true

            this.$http.tasks
                .setContractSigners({
                    contract_id: this.contract_id,
                    sending_signer_id: this.sending_signer_id,
                    receiving_signer_id: this.receiving_signer_id,
                })
                .then(async () => {
                    this.toast('success', 'The contract has been sent to the signers')
                    this.origin.receiving_signer_id = this.receiving_signer_id
                    this.origin.sending_signer_id = this.sending_signer_id

                    this.$store.commit('setTaskEntity', {})
                    await Gate.can('signView', 'task', this.$route.params.task_id).then(async (res) => {
                        if (res) {
                            return await this.$router.push({
                                name: 'TaskSign',
                                params: {id: this.$route.params.id, task_id: this.$route.params.task_id},
                            })
                        }
                    })
                })
                .catch(({response}) => {
                    this.toast('error', response.data.message)
                })
                .finally(() => {
                    this.sending = false
                })
        },
    },
}
</script>
<style lang="scss">
.taskSendSignature {
    &-arrow-table {
        width: 16px;
        height: 16px;
        object-fit: contain;
        cursor: pointer;
    }

    &-line {
        width: 30px;
        max-width: 30px;
        min-width: 30px;
        position: relative;
        display: flex;
        justify-content: center;

        img {
            width: 20px;
            height: 20px;
            object-fit: contain;
        }

        &-line {
            height: 60px;
            width: 1px;
            position: absolute;
            left: 50%;
            bottom: 0;
            background: #005D9D;
            transform: translate(0%, 50%);

            &.last {
                transform: translate(0%, 0%);
            }
        }

        &-center {
            height: 1px;
            width: 10px;
            position: absolute;
            left: 50%;
            bottom: 0;
            background: #005D9D;
        }
    }

    &__header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__subheader {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
    }

    &__title {
        font-size: 22px;
        font-weight: 600;
        color: #303c54;
    }

    &__type {
        font-size: 16px;
        font-weight: 600;
        color: #303c54;
    }

    &__row {
        font-size: 14px;
        font-weight: 400;
        color: #303c54;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        &-value {
            font-weight: 600;
        }
    }


    .btn-send, .btn-cancel {
        width: auto;
        height: 45px;

        @media (max-width: 767px) {
            width: 100%;
        }
    }
}
</style>
